import { Routes } from "@angular/router";
import { RrhhModule } from '../../components/modules/rrhh/rrhh.module';

export const content: Routes = [
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "single-page",
    loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  },
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "user",
    loadChildren: () => import("../../components/modules/_user/user.module").then((m) => m.UserModule),
  },
  {
    path: "rrhh",
    loadChildren: () => import("../../components/modules/rrhh/rrhh.module").then((m) => m.RrhhModule),
  },
  {
    path: "iot",
    loadChildren: () => import("../../components/modules/iot/iot.module").then((m) => m.IotModule),
  },
  {
    path: "store",
    loadChildren: () => import("../../components/store/store.module").then((m) => m.StoreModule),
  },
  {
    path: "sales",
    loadChildren: () => import("../../components/modules/sales/sales.module").then((m) => m.SalesModule),
  },
  {
    path: "setting",
    loadChildren: () => import("../../components/setting/setting.module").then((m) => m.SettingModule),
  },
  {
    path: "setting",
    loadChildren: () => import("../../components/management/management.module").then((m) => m.ManagementModule),
  },
  {
    path: "deyda",
    loadChildren: () => import("../../components/modules/deyda/deyda.module").then((m) => m.DeydaModule),
  },


];

