
<div class="media profile-media">
 
  <img class="b-r-10" [src]="userImage" style="width: 32px;" >
  <div class="media-body"><span>{{userName}}</span>
    <p class="mb-0 font-roboto">{{userRole}} <i class="middle fa fa-angle-down"></i></p>
    <p *ngIf="radioStatus" class="mb-0 font-roboto"><i class="middle fa fa-music"></i> {{truncatedStreamTitle}}</p>
    
  </div>
</div>

<ul class="profile-dropdown onhover-show-div">
  
  <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>cuenta</span></a></li>
   <!--  <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>mensajes</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>tareas</span></a></li>-->
  <li><a href="/user/settings"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>ajustes</span></a></li> 
  <li (click)="radioPlay()"><app-feather-icons [icon]="'music'"></app-feather-icons><span>{{radioStatusText}}</span></li>
  <li (click)="closeSession($event)"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>SALIR</span></li>
</ul>