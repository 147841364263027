import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders ,HttpParams,HttpResponse } from '@angular/common/http';
//import { ApiConfigService } from '../api-config.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserMenu } from '../../interfaces/UserMenu.interface';
import { environment } from '../../../environments/environment';




const USER_NAME = 'user_name';
const USER_EMAIL = 'user_email';
const USER_ROL = 'user_rol';
const USER_KEY = 'user_key';
const USER_PER_ID  = 'user_per_id';
const USER_ID  = 'user_id';
const USER_FIRST_NAME = 'user_first_name';
const USER_FULL_NAME = 'user_fullt_name';
const USER_NAMES = 'user_names';
const USER_ROL_ID = 'user_rol_id';
const USER_MENU = 'user_menu';
const USER_IMAGE = 'user_image';
const USER_PERMISSIONS = 'user_permissions';
const LOAD_PAGE = 'load_page'




@Injectable({
  providedIn: 'root'
})
export class UserService {

  private ENDPOINT = '/api/global';
  private URL
  private userDefaultRol: any;
  //private userMenu!: UserMenu;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    ) {

    this.URL = `${environment.HOST}${this.ENDPOINT}`;
  }

  getUserName(): string {
    return localStorage.getItem(USER_NAME)!;
  }

  setUserName(userName: any): void {
    localStorage.setItem(USER_NAME, userName);
  }

  getUserEmail(): string {
    return localStorage.getItem(USER_EMAIL)!;
  }

  setUserEmail(email: any): void {
    localStorage.setItem(USER_EMAIL, email);
  }

  getUserRol(): string {
    return localStorage.getItem(USER_ROL)!;
  }

  setUserRol(userRol: any): void {
    localStorage.setItem(USER_ROL, userRol);
  }

  setUserKey(userKey: any): void{
    localStorage.setItem(USER_KEY, userKey);
  }

  getUserKey(): string{
    return localStorage.getItem(USER_KEY)!;
  }

  setUserFirstName(firsName : any): void {
    localStorage.setItem(USER_FIRST_NAME,firsName);
  }  

  setUserNames(names : any): void {
    localStorage.setItem(USER_NAMES,names);
  }

  getUserNames(): string{
    return localStorage.getItem(USER_NAMES)!;
  }

  getUserFirstName(): string{
    return localStorage.getItem(USER_FIRST_NAME)!;
  }

  
  setUserFullName(fullName : any): void {
    localStorage.setItem(USER_FULL_NAME,fullName);
  }

  getUserFullName(): string{
    return localStorage.getItem(USER_FULL_NAME)!;
  }



  pageIsLoaded(): string{
    return localStorage.getItem(LOAD_PAGE)!;
  }

  pageLoaded():void{
    localStorage.setItem(LOAD_PAGE,"true")
  }

  setUserPersId(perId: any): void{
    localStorage.setItem(USER_PER_ID,perId)
  }

  getUserPersId(): string{
    return localStorage.getItem(USER_PER_ID)!;
  }

  setUserId(userId: any): void{
    localStorage.setItem(USER_ID,userId)
  }

  getUserId(): string{
    return localStorage.getItem(USER_ID)!;
  }


  setDefaulRol(rolId: any): void{
    localStorage.setItem(USER_ROL_ID,rolId)
  }

  getDefaulRol(): string{
    return localStorage.getItem(USER_ROL_ID)!;
  }

  setMenu(userMenu: string): void{
    localStorage.setItem(USER_MENU,userMenu)
  }

  getMenu(): UserMenu[]{
      const jsonString  = localStorage.getItem(USER_MENU)!;
      return JSON.parse(jsonString);
  }

  setImage(base64Data: string): void {
    localStorage.setItem(USER_IMAGE, base64Data);
  }

  getImage(): SafeUrl | null {
    const base64Data = localStorage.getItem(USER_IMAGE);
    if (base64Data) {
      const safeUrl = this.sanitizer.bypassSecurityTrustUrl(base64Data);
      return safeUrl;
    }
    return null;
  }

  setPermisos(permisos: string[]): void {
    localStorage.setItem(USER_PERMISSIONS, JSON.stringify(permisos));
  }

  getPermisos(): string[] {
    const permisos = localStorage.getItem(USER_PERMISSIONS);
    return permisos ? JSON.parse(permisos) : [];
  }

  checkPermission(permiso: any): boolean {
    const rolesUsuario = this.getPermisos();
    const rolesPermitidos = permiso as string[];
    console.log(rolesPermitidos);
    return rolesPermitidos.some(rol => rolesUsuario.includes(rol));
  }



  clearUser(): void{
    localStorage.removeItem(USER_NAME)
    localStorage.removeItem(USER_EMAIL)
    localStorage.removeItem(USER_ROL)
    localStorage.removeItem(USER_KEY)
    localStorage.removeItem(USER_FIRST_NAME)
    localStorage.removeItem(USER_FULL_NAME)
    localStorage.removeItem(USER_NAMES)    
    localStorage.removeItem(LOAD_PAGE)
    localStorage.removeItem(USER_ID)
    localStorage.removeItem(USER_PER_ID)
    localStorage.removeItem(USER_ROL_ID)
    localStorage.removeItem(USER_MENU)
    localStorage.removeItem(USER_IMAGE)
    localStorage.removeItem(USER_PERMISSIONS)
  }

  getUserInformation(loginData: any): Observable<any> {
    const params = new HttpParams()
      //.set('userId', loginData.email);
    const requestOptions  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      params : params
    };
    return this.http.get<any>(`${this.URL}/sessions/information`, requestOptions )
      .pipe(
        tap(res => {
          this.setUserName(res.data.general.userName)
          this.setUserEmail(res.data.general.email)
          this.setUserRol(res.data.general.userCargo)
          this.setUserKey(res.data.general.keycloakId)
          this.setUserFirstName(res.data.general.perPrimerNombre)
          this.setUserFullName(res.data.general.persNombrecompleto)
          this.setUserNames(res.data.general.persNombres)
          this.setUserPersId(res.data.general.persId)
          this.setUserId(res.data.general.id)
          this.userDefaultRol = this.selectUserRol(res.data.roles)

          if (this.userDefaultRol){
            this.setDefaulRol(this.userDefaultRol.sisRolesRolId)
          }else{
            this.setDefaulRol(0)
          }

        })
      );
  }

  getUserMenu(): Observable<any> {
    const params = new HttpParams()
      .set('endCat', 'we')
      //.set('userId', this.getUserId())
      .set('roldId', this.getDefaulRol());
    const requestOptions  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      params : params
    };
    return this.http.get<any>(`${this.URL}/sessions/menu`, requestOptions )
      .pipe(
        tap(res => {
          const jsonString  = JSON.stringify(res.data.menu);
          this.setMenu(jsonString)
          this.setPermisos(res.data.permiso)
        })
      );
  }



  getUserImage(): Observable<Blob> {
    const headers = new HttpHeaders();
    return this.http.get(`${this.URL}/sessions/foto?persId_=${this.getUserPersId()}`, { headers, responseType: 'blob' })
    .pipe(
      tap((response: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result as string;
          const imageUrl = URL.createObjectURL(response);
          const safeUrl = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
          this.setImage(base64Data);
          return safeUrl;
        };
        reader.readAsDataURL(response);
      })
    );
  }

  private selectUserRol(objeto: any[]): any | null {
    const objetoSeleccionado = objeto.find((elemento: any) => elemento.urlPrincipal === "s");
    return objetoSeleccionado || null;
  }


  getUserImage_(userId: any): Observable<Blob> {
    const headers = new HttpHeaders();
    return this.http.get(`${this.URL}/persona/foto/${userId}`, { headers, responseType: 'blob' });
  }



}
