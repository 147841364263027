import { HttpClient, HttpErrorResponse, HttpHeaders ,HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { TokenService } from './token.service';
import { catchError, Observable, tap, throwError } from 'rxjs';
//import { ApiConfigService } from '../api-config.service';
import { UserService } from '../user/user.service';
import { environment } from '../../../environments/environment';


const OAUTH_CLIENT = 'express-client';
const OAUTH_SECRET = 'express-secret';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
   // Authorization: 'Basic ' + Buffer.from(OAUTH_CLIENT + ':' + OAUTH_SECRET).toString('base64')
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private ENDPOINT = '/api/security';
  private URL

  redirectUrl = '';

  constructor(
    //private apiConfigService: ApiConfigService,
    private http: HttpClient,
    private tokenService: TokenService,
    private userService: UserService,
    ) {
    this.URL = `${environment.HOST}${this.ENDPOINT}`;
  }

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  private static log(message: string): any {
    console.log(message);
  }

  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('username', loginData.email)
      .set('password', loginData.password);

    return this.http.post<any>(`${this.URL}/login`, body, HTTP_OPTIONS)
      .pipe(
        tap(res => {

          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);

        }),
        catchError(AuthService.handleError)
      );
  }


  refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('refresh_token', refreshData.refresh_token)
      .set('grant_type', 'refresh_token');
    return this.http.post<any>(`${this.URL}/refresh`, body, HTTP_OPTIONS)
      .pipe(
        tap(res => {
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);
        }),
        catchError(AuthService.handleError)
      );
  }


  logout(): Observable<any> {

    const body = new HttpParams()
      .set('refresh_token', this.tokenService.getRefreshToken())
    return this.http.post<any>(`${this.URL}/logout`, body, HTTP_OPTIONS)
      .pipe(
        tap(res => {
              this.tokenService.removeToken();
              this.tokenService.removeRefreshToken();
              this.userService.clearUser()

        }),
        catchError(AuthService.handleError)
      );
  }

  /*
  register(data: any): Observable<any> {
    return this.http.post<any>(API_URL + 'oauth/signup', data)
      .pipe(
        tap(_ => AuthService.log('register')),
        catchError(AuthService.handleError)
      );
  }
  */

  /*
  secured(): Observable<any> {
    return this.http.get<any>(API_URL + 'secret')
      .pipe(catchError(AuthService.handleError));
  }

  */


  /*



  */


}
