import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import IcecastMetadataPlayer from "icecast-metadata-player";




@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {


  userName: string;
  userRole: string;
  userFirstName: string;
  userImage: SafeUrl | null | undefined;
  loadingImageUrl = 'assets/images/dashboard/profile.jpg';
  radioStatus: boolean = false
  radioStatusText: string = "RADIO"
  StreamTitle: string = ".";
  player = new IcecastMetadataPlayer(
  "https://sradio4505.conexize.com/4505.mp3",
  {
    onMetadata: (metadata) => {
      this.StreamTitle = metadata.StreamTitle;
      console.log(metadata)
    }
  }
);

  //public userName: string;
  //public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(
    private authService: AuthService,
    private userService :UserService,
    public router: Router) {


      this.userName = userService.getUserFirstName() + " ("+ userService.getUserName() + ")";
      this.userRole = userService.getUserRol();
      this.userFirstName = userService.getUserFirstName();

    //if (JSON.parse(localStorage.getItem("user"))) {
    //} else {
    //}


  }

  ngOnInit() {
    this.userImage = this.userService.getImage()

  }

  closeSession(event: Event): void {
    event.preventDefault();
      this.authService.logout().subscribe({
      next: () => {
        this.router.navigateByUrl('/login');
      },
      error: (e) => {
        console.log(e);
       },
      complete: () => console.info('complete')
    });
  }

  radioPlay() { 
    if (this.radioStatus == false) {
      this.player.play();
      this.radioStatus = true;      
      this.radioStatusText = "RADIO OFF"
    } else { 
      this.player.stop();
      this.radioStatus = false;
      this.radioStatusText = "RADIO"      
      this.StreamTitle= ""
      
    }
   
  }

  get truncatedStreamTitle(): string {
    const maxLength = 20; // Ajusta la longitud máxima según tus necesidades
    if (this.StreamTitle.length > maxLength) {
      return this.StreamTitle.substring(0, maxLength) + '...';
    }
    return this.StreamTitle;
  }
}
