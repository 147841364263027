import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { UserService } from 'src/app/services/user/user.service';
import * as path from "path";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(
    private router: Router,
    private userService :UserService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  

  createMenu(): Menu[] {
    const MENUITEMS: Menu[] = [
      {
        headTitle1: "GENERAL",
      },
      { path: "/dashboard", icon: "home", title: "Dashboard", active: false, type: "link", bookmark: true }
      // ,
      // {
      //   headTitle1: "CONFIGURACION Y REGISTROS DE SISTEMAS",
      // }
      // ,
      // { title: "developer",
      //   icon: "builders",
      //   type: "sub",
      //   badgeType: "light-primary",
      //   badgeValue: "4",
      //   active: true,
      //   children: [
      //     { path: "/setting/developer/systems", title: "sistemas", type: "link" },
      //      { path: "/setting/developer/options", title: "opciones", type: "link" },
      //     { path: "/setting/developer/category", title: "Categorias", type: "link" },
      //     { path: "/setting/developer/menu", title: "Menu", type: "link" },

      //     { path: "/setting/developer/permissions", title: "Permisos", type: "link" },
      //     { path: "/setting/management/client", title: "Clientes", type: "link" },
      //     { path: "/setting/management/role", title: "Roles", type: "link" },
      //     { path: "/setting/management/user", title: "Usuarios", type: "link" },
      //   ],
      // }



      /*,

        title: "Simple Page",
        icon: "home",
        type: "sub",
        badgeType: "light-primary",
        badgeValue: "2",
        active: true,
        children: [
          { path: "/simple-page/first-page", title: "First Page", type: "link" },
          { path: "/simple-page/second-page", title: "Second Page", type: "link" },
        ],
      },
      { path: "/single-page", icon: "search", title: "Single Page",  active: false, type: "link", bookmark: true },

      */
    
    ];

   // console.log(this.userService.getMenu());

    this.userService.getMenu().forEach(item => {
      //console.log(item);
      const menuItem: Menu = {
        headTitle1: item.sistNombre
      };

     const processedItems = this.procesarItem(item.children,item.sistIdent,"");

      MENUITEMS.push(menuItem);
      MENUITEMS.push(...processedItems);
    });


    return MENUITEMS;
  }

  private procesarItem(children: any[], SisIdent: String, Path: String ): Menu[] {
    const processedItems: Menu[] = [];

    if (children && children.length > 0) {

      //console.log("hijos");
      //console.log(children);

      children.forEach(child => {


        if (child.endpEndclasstipo == "u"){
          processedItems.push({
            path: "/" + SisIdent + "/"+ Path + "/" + child.endpEndclass,
            title: child.menuText,
            //icon: "home",
            type: "link",
            //children: processedChildItems
          });

        } else {
          const processedChildItems = this.procesarItem(child.children,SisIdent,child.menuPath);
          //console.log("aqui");
          //console.log(child);
          processedItems.push({
            title: child.menuText,
            icon: child.menuIcono,
            type: "sub",
            badgeType: "light-primary",
            badgeValue:  String(processedChildItems.length),
            active: false,
            children: processedChildItems
          });
        }

      });
    }

    return processedItems;
  }


  // Array
  items = new BehaviorSubject<Menu[]>(this.createMenu());
}
