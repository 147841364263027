import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { HttpClient, HttpErrorResponse, HttpHeaders ,HttpParams} from '@angular/common/http';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { TokenService } from '../../services/auth/token.service';
import {Router} from '@angular/router';
import { throwError } from 'rxjs';
import { switchMap, concatMap, catchError, tap } from 'rxjs/operators';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(
    private fb: FormBuilder, public router: Router,
    private userService: UserService,
    private authService: AuthService) {

    if (userService.getUserKey()) {
      this.router.navigateByUrl('/dashboard');
    }


    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  ngOnInit() {}

  login() {

    this.authService.login(this.loginForm.value).pipe(
      switchMap(() => this.userService.getUserInformation(this.loginForm.value)),
      switchMap(() => this.userService.getUserMenu()),
      switchMap(() => this.userService.getUserImage()),
      catchError((error) => {
        return throwError(() => new Error('ERROR BG'));
      })
    )
    .subscribe({
      next: (userInformation) => {
        console.log('Información del usuario:', userInformation);
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {
        this.router.navigateByUrl('/dashboard');
      }
    });


    /*

    if (this.loginForm.value["email"] == "Test@gmail.com" && this.loginForm.value["password"] == "test123") {
      let user = {
        email: "Test@gmail.com",
        password: "test123",
        name: "test user",
      };
      localStorage.setItem("user", JSON.stringify(user));
      this.router.navigate(["/dashboard/default"]);
    }

    */


  }

  showPassword(){
    this.show = !this.show
  }
}
