import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { TokenService } from './token.service';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserService } from '../user/user.service';
import { NgToastService } from 'ng-angular-popup';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private tokenService: TokenService,
        private authService: AuthService,
        private userService: UserService,
        private toast: NgToastService,
        ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {

        const token = this.tokenService.getToken();
        const refreshToken = this.tokenService.getRefreshToken();

        //console.log("BG HEADERS ANTES ", request)

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }

        if (request.body instanceof FormData) {
            //console.log("BG HEADERS IS MULTIPART ")
            request.headers.append('Content-Type', 'multipart/form-data')
            
        } else if (!request.headers.has('Content-Type')) {
            //console.log("NO SE ENCONTRO CONTEMT TYPE");
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
        });


         //console.log("BG HEADERS DESPUES",request)

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                   // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {


                console.log(error.error.error);

                if (error.status === 401) {
                    this.authService.refreshToken({ refresh_token: refreshToken })
                        .subscribe(() => {
                            location.reload();
                        });
                } else if (error.status === 500) {
                    this.toast.error({ detail: "ERROR", summary: 'EL SERVIDOR DE PUEDE PROCESAR SU SOLICITUD', duration: 5000, position: 'topRight' });
                    //Error al procesar los datos del servidor , comuníquese con su administrador de sistemas.
                    
                } else if (error.status === 404) {
                    this.toast.error({ detail: "ERROR", summary: 'NO DISPONIBLE', duration: 5000, position: 'topRight' });
                } else if (error.status === 409) {

                } else if (error.status === 503) {
                    this.toast.error({detail:"ERROR",summary:'NO DISPONIBLE',duration:5000, position: 'topRight'});
                    // El servicio no se encuentra disponible , intente nuevamente mas tarde, si el problema persiste comuníquese con su administrador de sistemas 
                } else { 
                    this.userService.clearUser();
                   this.router.navigate(['login']).then(_ => console.log('redirect to login'));
                }

                /*
                if (error.status === 401) {
                    if (error.error.error === 'Unauthorized') {
                        this.authService.refreshToken({ refresh_token: refreshToken })
                            .subscribe(() => {
                                location.reload();
                            });
                    } else {
                        this.router.navigate(['login']).then(_ => console.log('redirect to login'));
                    }
                }

                */

                return throwError(() => new Error(error.error.error));
            }));
    }
}
